import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import '../../App.css'
import '../../Css/Pagination.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";
import Select from 'react-select';
import JobseekerBase from "../Jobseeker/JobseekerBase";
import EmployerBase from "../Employer/EmployerBase";

export default function Jobseekerlists(any, i) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [from_date, setFrom_Date] = useState("");
  const [to_date, setTo_Date] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const [search_Experience, setSearch_Experience] = useState("");
  const [search_Salary, setSearch_Salary] = useState("");
  const [search_Qualification, setSearch_Qualification] = useState("");
  const [search_District, setSearch_District] = useState("");
  const [search_Sex, setSearch_Sex] = useState("");


  useEffect(() => {
    fetchDataAndSetState();
  }, []);

  const fetchDataAndSetState = async () => {
    setIsLoading(true);
    try {
      const todayAgo = new Date();
      const today = new Date(todayAgo);
      today.setDate(todayAgo.getDate() + 1);
      const tenDaysAgo = new Date(todayAgo);
      tenDaysAgo.setDate(todayAgo.getDate() - 30);

      const finalFromDate = from_date ? from_date : tenDaysAgo.toISOString().split("T")[0];
      setFrom_Date(finalFromDate);
      const finalToDate = to_date ? to_date : today.toISOString().split("T")[0];
      setTo_Date(finalToDate);
      var Param = { mstjobseekerid: 0, mstjobseekercode: finalFromDate, name: finalToDate };
      var url = "/JobSeekerTable/BindJobSeekerTable";
      // var url = "/JobSeekerTable/BindJobSeekerTable1";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const search = async () => {
    setIsLoading(true);
    try {
      var Param = { mstjobseekerid: 0, Experience: search_Experience, Salary_expectation: search_Salary, Qualifcation: search_Qualification, District: search_District, Sex: search_Sex };
      var url = "/JobSeekerTable/SearchBindJobSeekerTable";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const searchClear = async () => {
    setSearch_Experience("");
    setSearch_Salary("");
    setSearch_Qualification("");
    setSearch_District("");
    setSearch_Sex("");
    fetchDataAndSetState();
  };
  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    Get(rowData.id)
    setShowPopup(true);
  };
  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstjobseekerid, code: params.data.Mstjobseekercode, subscribe: params.data.Subscribe, Activestatus: params.data.Activestatus });
  };

  const columnDefs = [
    { headerName: 'Code', field: 'Mstjobseekercode', filter: true, enableFilter: true },
    { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
    { headerName: 'Sex', field: 'Sex', filter: true, enableFilter: true },
    { headerName: 'District', field: 'District', filter: true, enableFilter: true },
    { headerName: 'Qualifcation', field: 'Mstqualifcation', filter: true, enableFilter: true },
    { headerName: 'Skills', field: 'Skills', filter: true, enableFilter: true },
    { headerName: 'Salary Expectation', field: 'Salary_expectation', filter: true, enableFilter: true },
    { headerName: 'Experience', field: 'Experience', filter: true, enableFilter: true },
    {
      headerName: 'Preferred Location',
      field: 'Usertype',
      filter: true,
      enableFilter: true,
      valueGetter: (params) => {
        try {
          const usertypeArray = JSON.parse(params.data.Usertype);
          return usertypeArray.map(userType => userType.label).join(', ');
        } catch (error) {
          console.error('Error parsing Usertype:', error);
          return '';
        }
      },
    },
    // { headerName: 'CreatedDate', field: 'CreatedDate' },
  ];

  const [quickFilterText, setQuickFilterText] = useState('');

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const [aadharnumber, setAadharno] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [uploadFile, setuploadFile] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState([]);
  const [filename, setfilename] = useState("");
  const [plan, setPlan] = useState("");
  const [amount, setAmount] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [CountryList, setCountryList] = useState();
  const [StateList, setStateList] = useState();
  const [CityList, setCityList] = useState();
  const [selectedPlan, setselectedPlan] = useState("");
  const [selectedQualiify, setselectedQualiify] = useState([]);
  const [selectedLocationOptions, setSelectedOptions] = useState([]);
  const [formFields12, setFormFields] = useState([{ companyname: '', designation: '', years: '', month: '' },])
  const [formFields, setFormField] = useState([{ qualify: '', course: '', institution: '', passedout: '' },])
  useEffect(() => {
    GetQualification()
    // GetPlandetail()
    // GetAmount()
    GetCountryList()
    GetCityList()
    GetDistrict()
    GetSalary()
    GetExperience()
    GetSex()
  }, []);

  const [sexList, setSexList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [salaryList, setSalaryList] = useState([]);

  async function GetSex() {

    var url = "/DropDown/GetMaster_name?mastername=Sex";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setSexList(_response)
  }
  async function GetExperience() {

    var url = "/DropDown/GetMaster_name?mastername=Experience";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setExperienceList(_response)
  }
  async function GetSalary() {

    var url = "/DropDown/GetMaster_name?mastername=Salary";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setSalaryList(_response)
  }

  const handleFormChange = (event, index, F) => {

    let data = [...formFields12];
    if (F == 1) {
      data[index].companyname = event.target.value;
    } if (F == 2) {
      data[index].designation = event.target.value;
    } if (F == 3) {
      data[index].years = event.target.value;
    } if (F == 4) {
      data[index].month = event.target.value;
    }
    setFormFields(data);
  }


  const handleFormChange1 = (event, index, F) => {

    let data = [...formFields];
    if (F == 1) {
      data[index].qualify = event.target.value;
    } if (F == 2) {
      data[index].course = event.target.value;
    } if (F == 3) {
      data[index].institution = event.target.value;
    } if (F == 4) {
      data[index].passedout = event.target.value;
    }
    setFormField(data);
  }
  const submit = (e) => {
    e.preventDefault();
  }
  const addFields = () => {
    let object = { companyname: '', designation: '', years: '', month: '' }

    setFormFields([...formFields12, object])
  }
  const removeFields = (index) => {
    let data = [...formFields12];
    data.splice(index, 1)
    setFormFields(data)
  }
  const addFields1 = () => {
    let object = { qualify: '', course: '', institution: '', passedout: '' }

    setFormField([...formFields, object])
  }
  const removeFields1 = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormField(data)
  }
  async function GetCountryList() {
    let _response = await Api.DirectApiCall('country', "", "")
    setCountryList(_response)
  }
  async function GetStateList(value) {
    setCountry(value)
    var Param = { country: value }
    let _response = await Api.DirectApiCall('state', value)
    setStateList(_response.states)
  }
  async function GetCityList(value) {
    setState(value)
    var url = "/DropDown/GetCities";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setCityList(_response)
  }
  const [district, setDistrict] = useState("");
  const [districtlist, setDistrictlist] = useState("")
  async function GetDistrict() {
    var url = "/DropDown/GetDistrict";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDistrictlist(_response)
  }
  async function GetPlandetail() {

    var url = "/DropDown/GetPlandetail";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setPlan(_response)
  }
  async function GetAmount(e) {
    setselectedPlan(e.target.value)
    var id = (e.target.value)
    var url = "/DropDown/GetPlandetailamount?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setAmount(_response)
  }
  async function GetQualification() {
    var url = "/DropDown/GetQualification";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setQualification(_response)
  }
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    var filename = file.name;
    setfilename(filename)
    const base64 = await convertBase64(file);
    setuploadFile(base64);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  async function IUD_Delete() {
    openPopup1();
  }
  async function IUDOperation() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "U"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": JSON.stringify(selectedDistricts),
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": "",
      "active": "",
      "searchField": "",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "",
      "plantype": "",
      "planperiod": "",
      "activestatus": "",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,


    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup(false);

  }
  async function IUDOperationDelete() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "D"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": JSON.stringify(selectedDistricts),
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": "",
      "active": "",
      "searchField": "",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "",
      "plantype": "",
      "planperiod": "",
      "activestatus": "",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,


    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup1(false);

  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const showAlert1 = () => {
    Swal.fire({
      title: "Failed",
      text: "There is some error, Please contact Elakiyaa Groups",
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const districtOptions = Array.isArray(districtlist)
    ? districtlist.map((district) => ({
      value: district.Text,
      label: district.Text
    }))
    : [];

  const [file, setfile] = useState()
  const [filetype, setfiletype] = useState()
  function base64toPDF(data, types) {
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link)
      ;
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }
  function base64ToArrayBuffer(data) {
    var data1 = data.replace('data:documents/pdf;base64,', '');
    var bString = window.atob(data1);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  async function Get(id) {
    setSelectedOptions([]);
    setSelectedDistricts([]);

    var Param = { Mstjobseekerid: id }
    var url = "/JobSeekerTable/BindJobSeekerTable";
    let _response = await Api.BindGrid(Param, url)
    setAadharno(_response[0].Aadhaarnumber);
    setName(_response[0].Name);
    setDob(_response[0].Dataofbirth);
    setMobilenumber(_response[0].Mobilenumber);
    setEmail(_response[0].Email);
    setFormFields(JSON.parse(_response[0].Qualifcation));
    setselectedQualiify(_response[0].Mstqualifcation);
    setCountry(_response[0].Country);
    setSelectedDistricts(JSON.parse(_response[0].Usertype));
    setSelectedOptions(JSON.parse(_response[0].Usertype));
    setState(_response[0].State);
    setCity(_response[0].City);
    setAddress(_response[0].Address);
    setPincode(_response[0].Pincode);
    setselectedPlan(_response[0].Plan);
    setAmount(_response[0].Planamount);
    setDistrict(_response[0].District);
    setfilename(_response[0].Filename)
    var Files = _response[0].Filedocument;
    var base64String = Files.split(',')[1];
    var type = Files.split(';')[0].split('/')[1];
    setfiletype(type);
    setfile(base64String);
    setFormField(JSON.parse(_response[0].Education));
  }
  const downloadResume = () => {
    setIsLoading(true);
    try {
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `application/${filetype}` });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'resume.pdf';
      link.click();
      setIsLoading(false);

      URL.revokeObjectURL(link.href);
    } catch (error) {
      setIsLoading(false);
      showAlert1();
      console.error("Error decoding base64 string:", error);
    }
  }


  async function Active() {
    var ID = selectedRowData.id;
    var Activestatus = selectedRowData.Activestatus;
    if (Activestatus == "Active") {
      Inactive(ID)
    } else {
      Activate(ID)
    }
  }


  async function Inactive(ID) {
    var Param = {
      "mstjobseekerid": ID,
      "flag": "INACT"
    };
    var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
    let _response = await Api.IUD(Param, url);


    await fetchDataAndSetState();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);

  }
  async function Activate(ID) {
    var Param = {
      "mstjobseekerid": ID,
      "flag": "ACT"
    };
    var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
    let _response = await Api.IUD(Param, url);


    await fetchDataAndSetState();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);
  }
  const handleChange = (selectedOptions) => {
    setSelectedDistricts(selectedOptions);
  };
  function handleSearch() {
    fetchDataAndSetState();
  }
  async function ResumeDownload(selectedOptions) {
    setIsLoading(true);
    var Param = {
      "mstjoblistid": 0,
      "joblistcode": JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'],
      "jobtitle": "",
      "companyname": "",
      "experiencedetails": "",
      "vacancy": "",
      "disclosefromdate": "",
      "location": "",
      "skils": "",
      "jobdescription": "",
      "industrytype": "",
      "functionalarea": "",
      "role": "",
      "rolecategory": "",
      "employmenttype": "",
      "education": "",
      "active": "",
      "searchField": "",
      "createdBy": 0,
      "createdDate": "2022-11-17T05:12:20.480Z",
      "flag": "I",
      "disclosetodate": "",
    };
    var url = "/Postjobs/DownloadResume";
    let _response = await Api.IUD(Param, url);
    if (_response == "Insert successfully") {
      downloadResume()
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      alert(_response)
    }
  };



  return (
    <div>
      <div>

        <EmployerBase />
        <div id="main" class="main">
          <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li>
                <li class="breadcrumb-item active">Jobseeker List</li>
              </ol>
            </nav>
          </div>
          <div className="row mb-3">
            <div className=' col-md-3'>
              <input
                type="text"
                placeholder="Search..."
                onChange={onQuickFilterChange}
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div className=' col-md-3' style={{ display: 'none' }}>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]} class="form-control" placeholder='From Date' value={from_date} onChange={(e) => setFrom_Date(e.target.value)}
              />
            </div>
            <div className=' col-md-3' style={{ display: 'none' }}>
              <input
                type="date"
                max={new Date().toISOString().split("T")[0]} class="form-control" placeholder='To Date' value={to_date} onChange={(e) => setTo_Date(e.target.value)}
              />
            </div>
            <div className=' col-md-2' style={{ display: 'none' }}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <div className=' col-md-1'>
              {Api.CheckAdd(13) == 1 ?
                <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                  <Link to={'/JobseekerForm'}
                    state={{ ID: 0, Flag: 'I' }} style={{ color: "white" }}>
                    Add
                  </Link>
                </button>
                : null}

              <button
                type="button"
                className="filter-btn mx-4"
                id="dropdown-trigger"
                onClick={toggleDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  id="dropdown-trigger-img"
                >
                  <path
                    d="M0 1.3C0 0.955219 0.158035 0.624558 0.43934 0.380761C0.720644 0.136964 1.10218 0 1.5 0H18.5C18.8978 7.26572e-09 19.2794 0.136964 19.5607 0.380761C19.842 0.624558 20 0.955219 20 1.3C20 1.64478 19.842 1.97544 19.5607 2.21924C19.2794 2.46304 18.8978 2.6 18.5 2.6H1.5C1.10218 2.6 0.720644 2.46304 0.43934 2.21924C0.158035 1.97544 0 1.64478 0 1.3ZM4 6.5C4 6.15522 4.15804 5.82456 4.43934 5.58076C4.72064 5.33696 5.10218 5.2 5.5 5.2H14.5C14.8978 5.2 15.2794 5.33696 15.5607 5.58076C15.842 5.82456 16 6.15522 16 6.5C16 6.84478 15.842 7.17544 15.5607 7.41924C15.2794 7.66304 14.8978 7.8 14.5 7.8H5.5C5.10218 7.8 4.72064 7.66304 4.43934 7.41924C4.15804 7.17544 4 6.84478 4 6.5ZM8.5 10.4C8.10218 10.4 7.72064 10.537 7.43934 10.7808C7.15804 11.0246 7 11.3552 7 11.7C7 12.0448 7.15804 12.3754 7.43934 12.6192C7.72064 12.863 8.10218 13 8.5 13H11.5C11.8978 13 12.2794 12.863 12.5607 12.6192C12.842 12.3754 13 12.0448 13 11.7C13 11.3552 12.842 11.0246 12.5607 10.7808C12.2794 10.537 11.8978 10.4 11.5 10.4H8.5Z"
                    fill="white"
                  ></path>
                </svg>
              </button>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content px-4" id="dropdown-content">
                <div class="row">
                  <div class="col-md-6">
                    <label for="Sex"> Sex</label>
                    <select id="inputState" class="form-select" value={search_Sex} onChange={(e) => setSearch_Sex(e.target.value)}  >
                      <option selected>Sex</option>
                      {sexList &&
                        sexList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="district">District</label>
                    <select id="inputState" class="form-select" value={search_District} onChange={(e) => setSearch_District(e.target.value)}   >
                      <option selected>District</option>
                      {districtlist &&
                        districtlist.map((i, index) => (
                          <option value={i.Text}>{i.Text}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="Qualification">Qualification</label>
                    <select id="inputState" class="form-select" value={search_Qualification} onChange={(e) => setSearch_Qualification(e.target.value)}   >
                      <option selected>Qualification</option>
                      {qualification &&
                        qualification.map((i, index) => (
                          <option value={i.id}>{i.Text}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="salary">Salary Expectation</label>
                    <select id="inputState" class="form-select" value={search_Salary} onChange={(e) => setSearch_Salary(e.target.value)}   >
                      <option selected>Salary Expectation</option>
                      {salaryList &&
                        salaryList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6 mb-4">
                    <label for="Experience">Experience</label>
                    <select id="inputState" class="form-select" value={search_Experience} onChange={(e) => setSearch_Experience(e.target.value)}   >
                      <option selected>Experience</option>
                      {experienceList &&
                        experienceList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-4 mt-4 mb-4">
                    <button type="button" class="btn btn-primary" style={{ background: '#012fff' }} onClick={search}>Search</button>
                    <button type="button" class="btn btn-primary" style={{ background: '#012fff' }} onClick={searchClear}>Clear</button>
                  </div>
                </div>
              </div>
            )}

          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5 pt-5">
              <div className='pt-5 mt-5'>
                <BeatLoader className='mt-5' />
              </div>
            </div>
          ) : (
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={10}
                domLayout='autoHeight'
                floatingFilter={true}
                enableBrowserTooltips={true}
                onCellClicked={onCellClicked}
                quickFilterText={quickFilterText}
              />
            </div>
          )}
          {showPopup && (
            <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
              <Modal.Header closeButton>
                <Modal.Title> {selectedRowData.code}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="row g-3">

                  <div className='row mt-3'>
                    <div className='col-12'>
                      <label>Prefered Locations</label>
                      <div class="d-flex align-content-start flex-wrap">
                        {selectedLocationOptions.map((item01, index) => (
                          <p key={index} className="drp-texttile">
                            <span>{item01.label}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={ResumeDownload}>Download Resume</button>
                  </div>

                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>

      </div>
    </div>
  )
}
