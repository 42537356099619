import React, { useState, useEffect } from "react";
import Api from '../Services/ApiCall';
import Base from '../Base';
import '../../App.css'
import '../../Css/Pagination.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "../Admin/Admin_form/Filter/Globalfilter";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Swal from 'sweetalert2';
import { BeatLoader } from "react-spinners";
import Select from 'react-select';

export default function JobSeeker_table(any, i) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [from_date, setFrom_Date] = useState("");
  const [to_date, setTo_Date] = useState("");

  const [sexList, setSexList] = useState([]);
  const [experienceList, setExperienceList] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [qualification_brief, setQualification_brief] = useState("");
  const [skills, setSkills] = useState("");
  const [sex, setSex] = useState("");
  const [salary_Expectation, setSalary_Expectation] = useState("");
  const [experience, setExperience] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    fetchDataAndSetState();
  }, []);

  const fetchDataAndSetState = async () => {
    setIsLoading(true);
    try {
      const today = new Date();
      const tenDaysAgo = new Date(today);
      tenDaysAgo.setDate(today.getDate() - 7);

      const finalFromDate = from_date ? from_date : tenDaysAgo.toISOString().split("T")[0];
      setFrom_Date(finalFromDate);
      const finalToDate = to_date ? to_date : today.toISOString().split("T")[0];
      setTo_Date(finalToDate);
      var Param = { mstjobseekerid: 0 };
      var url = "/JobSeekerTable/BindJobSeekerTable";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const search = async () => {
    setIsLoading(true);
    try {
      var Param = { mstjobseekerid: 0,Experience: search_Experience,Salary_expectation: search_Salary,Qualifcation: search_Qualification,District: search_District,Sex: search_Sex };
      var url = "/JobSeekerTable/SearchBindJobSeekerTable";
      let _response1 = await Api.BindGrid(Param, url);
      setRowData(_response1);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };
  
  const searchClear = async () => {
    setSearch_Experience("");
    setSearch_Salary("");
    setSearch_Qualification("");
    setSearch_District("");
    setSearch_Sex("");
    fetchDataAndSetState();
  };

  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    Get(rowData.id)
    setShowPopup(true);
  };
  const openPopup1 = (rowData) => {
    setShowPopup(false);
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setSelectedRowData(null);
    setShowPopup1(false);
  };
  const closePopup = () => {
    setSelectedRowData(null);
    setShowPopup(false);
  };

  const onCellClicked = (params) => {
    openPopup({ id: params.data.Mstjobseekerid, code: params.data.Mstjobseekercode, subscribe: params.data.Subscribe, Activestatus: params.data.Activestatus });
  };

  const accessType = sessionStorage.getItem('AccessType');

  const allColumnDefs = [
    { headerName: 'Code', field: 'Mstjobseekercode', filter: true, enableFilter: true },
    { headerName: 'Name', field: 'Name', filter: true, enableFilter: true },
    { headerName: 'Sex', field: 'Sex', filter: true, enableFilter: true },
    { headerName: 'Mobile number', field: 'Mobilenumber', filter: true, enableFilter: true },
    { headerName: 'Aadhaar Number', field: 'Aadhaarnumber', filter: true, enableFilter: true },
    { headerName: 'Emailid', field: 'Email', filter: true, enableFilter: true },
    {
      headerName: 'Preferred Location',
      field: 'Usertype',
      filter: true,
      enableFilter: true,
      valueGetter: (params) => {
        try {
          const usertypeArray = JSON.parse(params.data.Usertype);
          return usertypeArray.map(userType => userType.label).join(', ');
        } catch (error) {
          console.error('Error parsing Usertype:', error);
          return '';
        }
      },
    },
    { headerName: 'District', field: 'District', filter: true, enableFilter: true },
    { headerName: 'Qualifcation', field: 'Mstqualifcation', filter: true, enableFilter: true },
    { headerName: 'Brief Qualification', field: 'Qualification_brief', filter: true, enableFilter: true },
    { headerName: 'Skills', field: 'Skills', filter: true, enableFilter: true },
    { headerName: 'Salary Expectation', field: 'Salary_expectation', filter: true, enableFilter: true },
    { headerName: 'Experience', field: 'Experience', filter: true, enableFilter: true },
    { headerName: 'Wallet Amount', field: 'Recharge_amount', filter: true, enableFilter: true },
    {
      headerName: 'Wallet Status',
      field: 'Recharge_amount',
      filter: true,
      enableFilter: true,
      valueGetter: (params) => {
        const amount = params.data.Recharge_amount;
        return amount < 300 ? 'Your amount is low' : 'Good';
      }
    },
    { headerName: 'CreatedStatus', field: 'CreatedStatus', filter: true, enableFilter: true },
    { headerName: 'CreatedBy', field: 'CreatedBy1', filter: true, enableFilter: true },
    { headerName: 'CreatedDate', field: 'CreatedDate' },
    { headerName: 'Status', field: 'Activestatus', filter: true, enableFilter: true },
    { headerName: 'Referal Code', field: 'ReferalCode', filter: true, enableFilter: true },

  ];

  const columnDefs = accessType === 'Agent'
    ? allColumnDefs.filter(col => col.field !== 'Recharge_amount' && col.field !== 'Mobilenumber')
    : allColumnDefs;

  const [quickFilterText, setQuickFilterText] = useState('');

  const onQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const [aadharnumber, setAadharno] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [uploadFile, setuploadFile] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState([]);
  const [filename, setfilename] = useState("resume.pdf");
  const [plan, setPlan] = useState("");
  const [amount, setAmount] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [CountryList, setCountryList] = useState();
  const [StateList, setStateList] = useState();
  const [CityList, setCityList] = useState();
  const [selectedPlan, setselectedPlan] = useState("");
  const [selectedQualiify, setselectedQualiify] = useState([]);
  const [formFields12, setFormFields] = useState([{ companyname: '', designation: '', years: '', month: '' },])
  const [formFields, setFormField] = useState([{ qualify: '', course: '', institution: '', passedout: '' },])
 
  const [search_Experience, setSearch_Experience] = useState("");
  const [search_Salary, setSearch_Salary] = useState("");
  const [search_Qualification, setSearch_Qualification] = useState("");
  const [search_District, setSearch_District] = useState("");
  const [search_Sex, setSearch_Sex] = useState("");
 
  useEffect(() => {
    GetQualification()
    GetCountryList()
    GetCityList()
    GetDistrict()
    GetExperience()
    GetSex()
    GetSalary()
  }, []);

  function handleSearch() {
    fetchDataAndSetState();
  }
  async function GetSex() {

    var url = "/DropDown/GetMaster_name?mastername=Sex";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setSexList(_response)
  }
  async function GetExperience() {

    var url = "/DropDown/GetMaster_name?mastername=Experience";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setExperienceList(_response)
  }
  async function GetSalary() {

    var url = "/DropDown/GetMaster_name?mastername=Salary";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setSalaryList(_response)
  }

  const handleFormChange = (event, index, F) => {

    let data = [...formFields12];
    if (F == 1) {
      data[index].companyname = event.target.value;
    } if (F == 2) {
      data[index].designation = event.target.value;
    } if (F == 3) {
      data[index].years = event.target.value;
    } if (F == 4) {
      data[index].month = event.target.value;
    }
    setFormFields(data);
  }


  const handleFormChange1 = (event, index, F) => {

    let data = [...formFields];
    if (F == 1) {
      data[index].qualify = event.target.value;
    } if (F == 2) {
      data[index].course = event.target.value;
    } if (F == 3) {
      data[index].institution = event.target.value;
    } if (F == 4) {
      data[index].passedout = event.target.value;
    }
    setFormField(data);
  }
  const submit = (e) => {
    e.preventDefault();
  }
  const addFields = () => {
    let object = { companyname: '', designation: '', years: '', month: '' }

    setFormFields([...formFields12, object])
  }
  const removeFields = (index) => {
    let data = [...formFields12];
    data.splice(index, 1)
    setFormFields(data)
  }
  const addFields1 = () => {
    let object = { qualify: '', course: '', institution: '', passedout: '' }

    setFormField([...formFields, object])
  }
  const removeFields1 = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormField(data)
  }
  async function GetCountryList() {
    let _response = await Api.DirectApiCall('country', "", "")
    setCountryList(_response)
  }
  async function GetStateList(value) {
    setCountry(value)
    var Param = { country: value }
    let _response = await Api.DirectApiCall('state', value)
    setStateList(_response.states)
  }
  async function GetCityList(value) {
    setState(value)
    // var url = "/DropDown/GetCities";
    // let _response = await Api.BindDropdown(url)
    // let Arry_Title1 = [];
    // setCityList(_response)
  }
  const [district, setDistrict] = useState("");
  const [districtlist, setDistrictlist] = useState("")
  async function GetDistrict() {
    var url = "/DropDown/GetDistrict";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setDistrictlist(_response)
  }
  async function GetPlandetail() {

    var url = "/DropDown/GetPlandetail";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setPlan(_response)
  }
  async function GetAmount(e) {
    setselectedPlan(e.target.value)
    var id = (e.target.value)
    var url = "/DropDown/GetPlandetailamount?id=" + id;
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setAmount(_response)
  }
  async function GetQualification() {
    var url = "/DropDown/GetQualification";
    let _response = await Api.BindDropdown(url)
    let Arry_Title1 = [];
    setQualification(_response)
  }
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    var filename = file.name;
    setfilename(filename)
    const base64 = await convertBase64(file);
    setuploadFile(base64);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  async function IUD_Delete() {
    openPopup1();
  }
  async function IUDOperation() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "U"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": JSON.stringify(selectedLocationOptions),
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": filename,
      "active": "",
      "searchField": "",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "",
      "plantype": "",
      "planperiod": "",
      "activestatus": "",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,
      "sex": sex,
      "qualification_brief": qualification_brief,
      "skills": skills,
      "salary_expectation": salary_Expectation,
      "experience": experience



    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup(false);
    fetchDataAndSetState();

  }
  async function IUDOperationDelete() {
    var AadharNo = aadharnumber;
    var Name = name;
    var Dateofbirth = dob;
    var Mobilenumber = mobilenumber;
    var Email = email;
    var Qualification = qualification;
    var Aadhar = aadharnumber;
    var UploadFile = uploadFile;
    var SelectedQualiify = selectedQualiify;
    var Country = country;
    var State = state;
    var City = city;
    var Address = address;
    var Pincode = pincode;
    var Plan = selectedPlan;
    var Amount = amount;
    var Flag = "D"
    var ID = selectedRowData.id

    var Param = {

      "mstjobseekerid": ID,
      "name": Name,
      "dataofbirth": Dateofbirth,
      "mobilenumber": Mobilenumber,
      "qualifcation": JSON.stringify(formFields12),
      "education": JSON.stringify(formFields),
      "usertype": JSON.stringify(selectedLocationOptions),
      "email": Email,
      "aadhaarnumber": Aadhar,
      "filedocument": UploadFile,
      "filename": "",
      "active": "",
      "searchField": "",
      "flag": Flag,
      "createdBy": 0,
      "createdDate": "2022-11-28T10:02:17.804Z",
      "subscribe": "",
      "plantype": "",
      "planperiod": "",
      "activestatus": "",
      "planamount": 0,
      "mstqualifcation": SelectedQualiify,
      "country": Country,
      "state": State,
      "city": City,
      "pincode": Pincode,
      "address": Address,
      "plan": Plan,
      "district": district,
      "sex": sex,
      "qualification_brief": qualification_brief,
      "skills": skills,
      "salary_expectation": salary_Expectation,
      "experience": experience


    };

    var url = "/JobSeekerTable/IUDJobSeekerTable";
    let _response = await Api.IUD(Param, url);

    showAlert(_response);
    setShowPopup1(false);
    fetchDataAndSetState();
  }
  const showAlert = (_response) => {
    Swal.fire({
      title: "Success",
      text: _response,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const districtOptions = Array.isArray(districtlist)
    ? districtlist.map((district) => ({
      value: district.Text,
      label: district.Text
    }))
    : [];

  const [file, setfile] = useState()
  const [filetype, setfiletype] = useState()
  function base64toPDF(data, types) {
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link)
      ;
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }
  function base64ToArrayBuffer(data) {
    var data1 = data.replace('data:documents/pdf;base64,', '');
    var bString = window.atob(data1);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  async function Get(id) {
    setAadharno("");
    setName("");
    setDob("");
    setMobilenumber("");
    setEmail("");
    setFormFields("");
    setselectedQualiify("");
    setCountry("");
    setSelectedDistricts("");
    setSelectedOptions("");
    setState("");
    setCity("");
    setAddress("");
    setPincode("");
    setselectedPlan("");
    setAmount("");
    setDistrict("");
    setfilename("");
    setfiletype("")
    setfile("");
    setFormField("");
    setQualification_brief("");
    setSkills("");
    setSex("");
    setSalary_Expectation("");
    setExperience("");
    setSelectedDistricts([]);
    setSelectedOptions([]);

    var Param = { Mstjobseekerid: id }
    var url = "/JobSeekerTable/BindJobSeekerTable";
    let _response = await Api.BindGrid(Param, url)
    setAadharno(_response[0].Aadhaarnumber);
    setName(_response[0].Name);
    setDob(_response[0].Dataofbirth);
    setMobilenumber(_response[0].Mobilenumber);
    setEmail(_response[0].Email);
    setState(_response[0].State);
    setCity(_response[0].City);
    setAddress(_response[0].Address);
    setPincode(_response[0].Pincode);
    setselectedPlan(_response[0].Plan);
    setAmount(_response[0].Planamount);
    setDistrict(_response[0].District);
    setfilename(_response[0].Filename)
    var Files = _response[0].Filedocument;
    var type = Files.split(";")[0].split('/')[1];
    var test = Files.split(",")[1];
    setfiletype(type)
    setfile(test);
    setselectedQualiify(_response[0].Mstqualifcation);
    setCountry(_response[0].Country);
    setQualification_brief(_response[0].Qualification_brief);
    setSkills(_response[0].Skills);
    setSex(_response[0].Sex);
    setSalary_Expectation(_response[0].Salary_expectation);
    setExperience(_response[0].Experience);

    setFormFields(JSON.parse(_response[0].Qualifcation));
    setSelectedDistricts(JSON.parse(_response[0].Usertype));
    setSelectedOptions(JSON.parse(_response[0].Usertype));
    setFormField(JSON.parse(_response[0].Education));
  }

  async function Active() {
    var ID = selectedRowData.id;
    var Activestatus = selectedRowData.Activestatus;
    if (Activestatus == "Active") {
      Inactive(ID)
    } else {
      Activate(ID)
    }
  }


  async function Inactive(ID) {
    var Param = {
      "mstjobseekerid": ID,
      "flag": "INACT"
    };
    var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
    let _response = await Api.IUD(Param, url);


    await fetchDataAndSetState();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);

  }
  async function Activate(ID) {
    var Param = {
      "mstjobseekerid": ID,
      "flag": "ACT"
    };
    var url = "/JobSeekerTable/IUDJobseekerInactiveActive";
    let _response = await Api.IUD(Param, url);


    await fetchDataAndSetState();
    setShowPopup1(false);
    setShowPopup(false);
    toast.success(_response);
  }
  const [selectedLocationOptions, setSelectedOptions] = useState([]);
  const handleChange = (selectedOptions) => {
    const isValueSelected = selectedLocationOptions.some((option) => option.label === selectedOptions[0].value);
    if (!isValueSelected) {
      const newOption = {
        label: selectedOptions[0].value,
      };
      setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, newOption]);
    }
    setSelectedDistricts(selectedOptions);
  };

  const handleRemove = (labelToRemove) => {
    setSelectedDistricts((prevSelected) =>
      prevSelected.filter((item) => item.label !== labelToRemove)
    );
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((item) => item.label !== labelToRemove)
    );
  };

  async function GetCityValue(value) {
    setDistrict(value)
    var Param = { districtName: value, cityName: "" }
    var url = "/CommanAPI/GetCity";
    let _response = await Api.BindGrid(Param, url)
    setCityList(_response)
  }

  const downloadResume = () => {
    try {
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: `application/${filetype}` });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'resume.pdf';
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error decoding base64 string:", error);
    }
  }

  return (
    <div>
      <div>

        <Base Jobseekercreate={true} />
        <div id="main" class="main">
          <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/dash">Home</Link>
                </li>

                <li class="breadcrumb-item">Services</li>
                <li class="breadcrumb-item active">Jobseeker Registrtion</li>
              </ol>
            </nav>
          </div>
          <input
            type="text"
            placeholder="Search..."
            onChange={onQuickFilterChange}
            style={{ marginBottom: '10px' }}
          />
          {Api.CheckAdd(13) == 1 ?
            <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
              <Link to={'/JobseekerForm'}
                state={{ ID: 0, Flag: 'I' }} style={{ color: "white" }}>
                Add
              </Link>
            </button>
            : null}

          <button
            type="button"
            className="filter-btn mx-4"
            id="dropdown-trigger"
            onClick={toggleDropdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="13"
              viewBox="0 0 20 13"
              fill="none"
              id="dropdown-trigger-img"
            >
              <path
                d="M0 1.3C0 0.955219 0.158035 0.624558 0.43934 0.380761C0.720644 0.136964 1.10218 0 1.5 0H18.5C18.8978 7.26572e-09 19.2794 0.136964 19.5607 0.380761C19.842 0.624558 20 0.955219 20 1.3C20 1.64478 19.842 1.97544 19.5607 2.21924C19.2794 2.46304 18.8978 2.6 18.5 2.6H1.5C1.10218 2.6 0.720644 2.46304 0.43934 2.21924C0.158035 1.97544 0 1.64478 0 1.3ZM4 6.5C4 6.15522 4.15804 5.82456 4.43934 5.58076C4.72064 5.33696 5.10218 5.2 5.5 5.2H14.5C14.8978 5.2 15.2794 5.33696 15.5607 5.58076C15.842 5.82456 16 6.15522 16 6.5C16 6.84478 15.842 7.17544 15.5607 7.41924C15.2794 7.66304 14.8978 7.8 14.5 7.8H5.5C5.10218 7.8 4.72064 7.66304 4.43934 7.41924C4.15804 7.17544 4 6.84478 4 6.5ZM8.5 10.4C8.10218 10.4 7.72064 10.537 7.43934 10.7808C7.15804 11.0246 7 11.3552 7 11.7C7 12.0448 7.15804 12.3754 7.43934 12.6192C7.72064 12.863 8.10218 13 8.5 13H11.5C11.8978 13 12.2794 12.863 12.5607 12.6192C12.842 12.3754 13 12.0448 13 11.7C13 11.3552 12.842 11.0246 12.5607 10.7808C12.2794 10.537 11.8978 10.4 11.5 10.4H8.5Z"
                fill="white"
              ></path>
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content px-4" id="dropdown-content">
              <div class="row">
                <div class="col-md-6">
                  <label for="Sex"> Sex</label>
                  <select id="inputState" class="form-select" value={search_Sex} onChange={(e) => setSearch_Sex(e.target.value)}  >
                    <option selected>Sex</option>
                    {sexList &&
                      sexList.map((i, index) => (
                        <option value={i.id}>{i.Text1}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="district">District</label>
                  <select id="inputState" class="form-select" value={search_District} onChange={(e) => setSearch_District(e.target.value)}   >
                    <option selected>District</option>
                    {districtlist &&
                      districtlist.map((i, index) => (
                        <option value={i.Text}>{i.Text}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="Qualification">Qualification</label>
                  <select id="inputState" class="form-select" value={search_Qualification} onChange={(e) => setSearch_Qualification(e.target.value)}   >
                    <option selected>Qualification</option>
                    {qualification &&
                      qualification.map((i, index) => (
                        <option value={i.id}>{i.Text}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="salary">Salary Expectation</label>
                  <select id="inputState" class="form-select" value={search_Salary} onChange={(e) => setSearch_Salary(e.target.value)}   >
                    <option selected>Salary Expectation</option>
                    {salaryList &&
                      salaryList.map((i, index) => (
                        <option value={i.id}>{i.Text1}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-6 mb-4">
                  <label for="Experience">Experience</label>
                  <select id="inputState" class="form-select" value={search_Experience} onChange={(e) => setSearch_Experience(e.target.value)}   >
                    <option selected>Experience</option>
                    {experienceList &&
                      experienceList.map((i, index) => (
                        <option value={i.id}>{i.Text1}</option>
                      ))}
                  </select>
                </div>
                <div class="col-md-4 mt-4 mb-4">
                  <button type="button" class="btn btn-primary" style={{ background: '#012fff' }} onClick={search}>Search</button>
                  <button type="button" class="btn btn-primary" style={{ background: '#012fff' }} onClick={searchClear}>Clear</button>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="d-flex justify-content-center my-5 pt-5">
              <div className='pt-5 mt-5'>
                <BeatLoader className='mt-5' />
              </div>
            </div>
          ) : (
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={10}
                domLayout='autoHeight'
                floatingFilter={true}
                enableBrowserTooltips={true}
                onCellClicked={onCellClicked}
                quickFilterText={quickFilterText}
              />
            </div>
          )}
          {showPopup && (
            <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
              <Modal.Header closeButton>
                <Modal.Title> {selectedRowData.code}</Modal.Title>
                <Link to={'/Rechargeform'} state={{
                  Code: selectedRowData.code
                }}>
                  <button type="button"
                    class={selectedRowData.subscribe == "Not Paid" ? "btn btn-danger mx-5" : "btn btn-success mx-5"}
                  >Subscribe</button>
                </Link>
              </Modal.Header>
              <Modal.Body>
                <div class="row g-3">

                  <div class="col-md-6">
                    <label for="name"> Name</label>
                    <input type="text" class="form-control" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="Sex"> Sex</label>
                    <select id="inputState" class="form-select" value={sex} onChange={(e) => setSex(e.target.value)}  >
                      <option selected>Sex</option>
                      {sexList &&
                        sexList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="aadharnumber">Aadhar Number</label>
                    <input type="text" class="form-control no-spinner" value={aadharnumber} placeholder="Aadhar no" onChange={(e) => setAadharno(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="dob"> Date of Birth</label>
                    <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" value={dob} placeholder="Date of birth" onChange={(e) => setDob(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="mobilenumber"> Mobile Number</label>
                    <input type="number" class="form-control no-spinner" placeholder="Mobile number" value={mobilenumber} onChange={(e) => setMobilenumber(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="email">Email Id</label>
                    <input type="email" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="qualification"> Qualifcation</label>
                    <select id="inputState" class="form-select" value={selectedQualiify} onChange={(e) => setselectedQualiify(e.target.value)}  >
                      <option selected>Qualification</option>
                      {qualification &&
                        qualification.map((i, index) => (
                          <option value={i.id}>{i.Text}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="Please enter brief your Qualifcation">Please enter brief your Qualifcation</label>
                    <input type="text" class="form-control" placeholder="Please enter brief your Qualifcation" value={qualification_brief} onChange={(e) => setQualification_brief(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="Skills">Skills</label>
                    <input type="text" class="form-control" placeholder="Skills" value={skills} onChange={(e) => setSkills(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="Salary Expectation"> Salary Expectation</label>
                    <select id="inputState" class="form-select" value={salary_Expectation} onChange={(e) => setSalary_Expectation(e.target.value)}  >
                      <option selected>Salary Expectation</option>
                      {salaryList &&
                        salaryList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="Experience"> Experience</label>
                    <select id="inputState" class="form-select" value={experience} onChange={(e) => setExperience(e.target.value)}  >
                      <option selected>Experience</option>
                      {experienceList &&
                        experienceList.map((i, index) => (
                          <option value={i.id}>{i.Text1}</option>
                        ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="country">Country</label>
                    <select id="inputState" class="form-select" value={country} onChange={(e) => GetStateList(e.target.value)}>
                      <option selected>Country</option>
                      {CountryList?.map((i, index) => (
                        <option value={i.country.toLowerCase()}>{i.country}</option>
                      ))}

                    </select>
                  </div>


                  <div class="col-md-6">
                    <label for="state">State</label>
                    <select id="inputState" class="form-select" value={state} onChange={(e) => GetCityList(e.target.value)}>
                      <option selected >State</option>
                      {StateList?.map((i, index) => (
                        <option value={i.name.toLowerCase()}>{i.name}</option>
                      ))}

                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="district">District</label>
                    <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}                    >
                      <option selected >District</option>
                      {districtlist &&
                        districtlist.map((i, index) => (
                          <option value={i.Text}>{i.Text}</option>
                        ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="city">City</label>
                    <select id="inputState" class="form-select" value={city} onChange={(e) => setCity(e.target.value)}>

                      <option >City</option>
                      {CityList?.map((i, index) => (
                        <option value={i.CityName}>{i.CityName}</option>
                      ))}
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="address">Address</label>
                    <textarea class="form-control" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                  </div>
                  <div class="col-md-6">
                    <label for="pincode">Pincode</label>
                    <input type="number" class="form-control no-spinner" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                  </div>

                  <div class="col-md-6">
                    <label for="attachresume">
                      {file && file !== "" ? "ReAttach Resume" : "Attach Resume"}
                    </label>
                    {/* <label for="attachresume">Attach Resume</label> */}
                    <input type="file" name="file" class="form-control" onChange={(e) => uploadImage(e)} />

                  </div>


                  <div className="col-md-6">
                    <label htmlFor="district">Preferred location</label>
                    <Select
                      id="preferredLocation"
                      isMulti
                      classNamePrefix="select"
                      options={districtOptions}
                      onChange={handleChange}
                      value={[]}
                      placeholder="Prefared Location"
                    />
                  </div>

                  <div className='row '>
                    <div className='col-12'>
                      <div class="d-flex align-content-start flex-wrap">
                        {selectedLocationOptions.map((item01, index) => (
                          <p key={index} className="drp-texttile">
                            <span>{item01.label}</span>
                            <span className="drpclose" onClick={() => handleRemove(item01.label)} style={{ cursor: 'pointer', marginLeft: '8px' }}
                            >
                              x
                            </span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    {Api.CheckUpdate(13) == 1 ?
                      <button type="button" class="btn btn-primary" style={{ background: 'green' }} onClick={IUDOperation}>Update</button>
                      : null}
                    {Api.CheckDelete(13) == 1 ?
                      <button type="reset" class="btn btn-info" style={{ background: 'red', color: '#ffffff' }} onClick={IUD_Delete} >Delete</button>
                      : null}
                    <button type="reset" class="btn btn-info mx-3" onClick={Active} style={{ background: '#012fff', color: '#ffffff' }} >Active/Inactive</button>
                    {file && (
                      <button onClick={downloadResume} className="btn btn-info" style={{ background: '#012fff', color: '#ffffff' }}>
                        Download Resume
                      </button>
                    )}
                  </div>

                </div>
              </Modal.Body>
            </Modal>
          )}
          {showPopup1 && (
            <Modal show={true} onHide={closePopup1} >
              <Modal.Header closeButton>
                <Modal.Title> {selectedRowData.code}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are You sure want to Delete?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup1}>
                  Close
                </Button>
                <Button variant="secondary" onClick={IUDOperationDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>

      </div>
    </div >
  )
}
